import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { clsx } from "clsx";
import getImageUrlWithPreset, {
  ProductImagePresetKeys
} from "@RHCommerceDev/utils/getImageUrlWithPreset";
import { FALLBACK_IMAGE_URL } from "@RHCommerceDev/utils/constants";
import { processEnvServer } from "hooks/useSsrHooks";
import memoize from "utils/memoize";

export interface RHImageProps extends React.ComponentProps<"img"> {
  preset?: ProductImagePresetKeys;
  analyticsId?: string;
  containerProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  skeletonProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  skeletonComponent?: React.FC<{
    className?: string;
    style?: React.CSSProperties;
  }>;
}

type State = "loading" | "success" | "error";

const DefaultSkeleton: React.FC<{
  className?: string;
  style?: React.CSSProperties;
}> = ({ className, style }) => (
  <div
    className={clsx("absolute inset-0 bg-gray-500 animate-pulse", className)}
    style={style}
  />
);

const Image: React.FC<React.ComponentProps<"img"> & { state: State }> = ({
  state,
  ...props
}) => (
  <img
    {...props}
    className={clsx(
      "w-full h-full",
      state === "loading" ? "opacity-0" : "opacity-1",
      props.className
    )}
  />
);

const Container: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}> = ({ className, style, children }) => (
  <div className={clsx("relative w-full h-full", className)} style={style}>
    {children}
  </div>
);

const RHImage = forwardRef<HTMLImageElement, RHImageProps>(
  (
    {
      className,
      src,
      preset,
      analyticsId,
      containerProps,
      skeletonProps,
      skeletonComponent: SkeletonComponent = DefaultSkeleton,
      loading = "lazy",
      onLoad,
      onError,
      ...props
    },
    ref
  ) => {
    const [state, setState] = useState<State>(
      processEnvServer ? "success" : "loading"
    );

    const imageUrl = useMemo(() => {
      if (state === "error") {
        return FALLBACK_IMAGE_URL;
      }

      return preset ? getImageUrlWithPreset(src, preset) : src;
    }, [state, preset, src]);

    const handleLoad = useCallback(
      e => {
        if (state === "error") {
          return;
        }
        onLoad?.(e);
        setState("success");
      },
      [onLoad, state]
    );

    const handleError = useCallback(
      e => {
        onError?.(e);
        setState("error");
      },
      [onError]
    );

    return (
      <Container
        className={containerProps?.className}
        style={containerProps?.style}
      >
        <Image
          ref={ref}
          src={imageUrl}
          onLoad={handleLoad}
          onError={handleError}
          loading={loading}
          data-analytics-id={analyticsId}
          state={state}
          {...props}
          className={className}
        />
        {state === "loading" && <SkeletonComponent {...skeletonProps} />}
      </Container>
    );
  }
);

export default memoize(RHImage);
